import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { AllowedStatuses, JustADot } from 'components/charts/StatusChart';
import {
  VerTableStore,
  ColumnRecordLink,
  ColumnReferences,
  ColumnTimestamp,
  ColumnCustom,
  ColumnModel,
} from 'components/table/TableS';
import { Table } from 'components/table/Table';

const ScriptsRunTab = withRouter(
  inject(
    'store',
    'instance'
  )(
    observer((props) => {
      let query = "inherits('std::host/ScriptRun:1')";
      if (props.record.host_1) {
        query += ` AND std::host/ScriptRun:1.targets == '${props.record.id}'`;
      } else {
        query += ` AND std::host/ScriptRun:1.script == '${props.record.id}'`;
      }

      const [store] = useState(
        VerTableStore.create({
          paginationDefaultRowsPerPage: 15,
          paginationRowsPerPageOptions: [10, 15, 25, 50, 100],
          selectable: false,
          query: query,
          columns: [
            ColumnModel.create({}),
            ColumnCustom.create({ name: 'State', opts: { width: '80px' } }).setRender((row) => {
              const state = row.data.get('std::host/ScriptRun:1').get('state');

              const status = {
                unknown: AllowedStatuses.Unknown,
                queued: AllowedStatuses.Pending,
                running: AllowedStatuses.Running,
                'failed-to-start': AllowedStatuses.Critical,
                crashed: AllowedStatuses.Critical,
                interrupted: AllowedStatuses.Critical,
                finished: AllowedStatuses.Ok,
              }[state];
              return <JustADot title={state} status={status} />;
            }),
            ColumnRecordLink.create({ name: 'Script Run' }),
            ColumnReferences.create({ name: 'Script', keys: ['std::host/ScriptRun:1.script'] }),
            ColumnReferences.create({ name: 'Initiated By', keys: ['std::host/ScriptRun:1.user'] }),
            ColumnReferences.create({ name: 'Executed On', keys: ['std::host/ScriptRun:1.targets'] }),
            ColumnTimestamp.create({ name: 'Started At', key: 'std::host/ScriptRun:1.startedAt' }),
            ColumnCustom.create({ name: 'Duration' }).setRender((row) => {
              const scriptRun = row.data.get('std::host/ScriptRun:1');
              const startedAt = scriptRun && scriptRun.get('startedAt');
              const completedAt = scriptRun && scriptRun.get('completedAt');

              if (!startedAt) {
                return 'not started';
              }
              const duration = (completedAt || Math.floor(Date.now())) - startedAt;
              return <span title={`${duration} seconds`}>{moment.duration(duration, 'milliseconds').humanize()}</span>;
            }),
          ],
        })
      );

      useEffect(() => {
        store.setUp({ instance: props.instance, transport: props.store.TransportLayer });
      }, []);

      return <Table store={store} />;
    })
  )
);

ScriptsRunTab.suites = (record) => record.script_1 || record.host_1;

export default ScriptsRunTab;
