import { types, getParent } from 'mobx-state-tree';

import requests from 'lib/requests';
import { setRootToken, getRootToken, cleanData, getTokens } from 'lib/token';
import { UserRoles, LoginActions } from 'Constants';
import { PasswordInputStore } from 'components/forms/Input';
import { makePasswordEqualityValidator } from 'components/forms/Input/PasswordInputS';
import { getLoginURL } from 'components/RedirectToLogin';
import Store from './Store';

const User = types
  .model('User', {
    email: types.string,
    firstName: types.string,
    lastName: types.string,
    role: types.string,
  })
  .views((self) => ({
    get fullName() {
      return `${self.firstName} ${self.lastName}`;
    },
    get isAdmin() {
      return self.role === UserRoles.ADMIN;
    },
  }));

const Profile = types
  .model('Profile', {
    token: types.maybeNull(types.string),
    user: types.maybeNull(User),
    errors: types.maybeNull(types.string),
    loading: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    saveToken(token, email, organization) {
      setRootToken(token, email, organization);
    },
    setToken(token, email, organization) {
      self.token = token;
      self.saveToken(token, email, organization);
      self.getUser();
    },
    setError(message) {
      self.errors = message;
    },
    setUser(userdata) {
      self.user = User.create({
        email: userdata.email,
        firstName: userdata.first_name,
        lastName: userdata.last_name,
        role: userdata.role,
      });
      self.errors = null;
      self.loading = false;
      getParent(self).Organization.Info.setDisplayName(userdata.org_display_name);
    },
    finishLoading() {
      self.loading = false;
    },
    getUser() {
      self.loading = true;
      // NOTE(andreykurilin): we cannot call Store.TransportLayer here since
      //    getUser method can be called before parent Store finishes
      //    initialization.
      requests.GET({
        url: '/m/api/v1/auth/tokens/info',
        authToken: self.token,
        onFailure: self.globalOnFailure,
        onSuccess: (response, response_data) => {
          self.setUser(response_data.data.owner);
        },
        onFinish: () => {
          self.finishLoading();
        },
      });
    },
    logout(loginAction = LoginActions.logout) {
      Object.values(getTokens()).forEach((token) => {
        // call to remove root token in the end
        if (token !== self.token) {
          requests.POST({
            url: '/i/api/v1/auth/logout',
            authToken: token,
          });
        }
      });
      requests.POST({
        url: '/m/api/v1/auth/tokens/revoke',
        authToken: self.token,
      });

      // no need to wait the final result
      cleanData();
      // NOTE(andreykurilin): no need to reset store explicitly here, since it
      //  will result in redundant re-rendering by react-router at App.jsx.
      //  Setting location directly with window.location should reset store
      //  automatically.

      window.location.href = getLoginURL(loginAction, loginAction.includeRedirectURL);
    },
    globalOnFailure(response, errors, onFailure) {
      if (response.status === 403 && !response.url.includes('logout')) {
        self.logout(LoginActions['renew-token']);
      } else {
        if (onFailure) {
          onFailure(response, errors);
        }
      }
    },

    // NOTE(andreykurilin): do not use MST create hook, since it will be executed
    //  before making initial snapshot of the whole state. This may result in
    //  applying invalid token even after logout
    loadTokenFromLocalStorage() {
      const token = getRootToken();
      if (token) {
        self.token = token;
        self.getUser();
      }
    },
  }))
  .views((self) => ({
    get isLogged() {
      return self.user !== null;
    },
  }));

export default Profile;

export const PasswordChangeForm = types
  .model('PasswordChangeForm', {
    current: types.optional(PasswordInputStore, () => PasswordInputStore.create({ label: 'Current password' })),
    newPassword1: types.optional(PasswordInputStore, () =>
      PasswordInputStore.create({ label: 'New password', schema: {} })
    ),
    newPassword2: types.optional(PasswordInputStore, () =>
      PasswordInputStore.create({ label: 'Confirm new password' })
    ),
    error: types.maybeNull(types.string),
    message: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    afterCreate() {
      self.newPassword1.registerOnChangeHandler(self.onPassword1Change);
      self.newPassword2.addInputValidator(makePasswordEqualityValidator(self.newPassword1));
    },
    onPassword1Change() {
      if (self.newPassword2.value !== null) {
        self.newPassword2.validate();
      }
    },
    setLoading(loading) {
      self.loading = loading;
    },
    setError(message) {
      self.error = message;
    },
    setMessage(message) {
      self.message = message;
    },
    clear() {
      self.error = null;
      self.loading = true;
      self.message = null;
    },
    submitNewPassword(email) {
      self.clear();

      Store.TransportLayer.put({
        url: `/m/api/v1/users/${email}`,
        body: {
          old_password: self.current.value,
          password: self.newPassword1.value,
        },
        onFailure: (_, errors) => {
          self.setError(errors.join('. '));
        },
        onSuccess: () => {
          self.setMessage('Password successfully updated.');
        },
        onFinish: () => {
          self.setLoading(false);
        },
      });
    },
  }))
  .views((self) => ({
    get filled() {
      return self.current.isDone() && self.newPassword1.isDone() && self.newPassword2.isDone();
    },
  }));
