import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer, inject, Provider } from 'mobx-react';

import Icon from 'components/Icon';
import { Terminals } from 'components/terminal/Navigation';
import { LoginActions, InstanceReadinessStages } from 'Constants';

import { useStores } from 'stores/Store';
import InstanceGlobalSearch from 'pages/Instances/GlobalSearch/NavigationComponent';

const BrandBox = inject('settings')(
  observer((props) => {
    return (
      <div className="brand-box">
        <div
          className="logo"
          onClick={() => {
            // NOTE(andreykurilin): Pinned view hides div(className="pin') and
            //  the logo itself becomes a button for unpinning. With unpinned
            //  view logo should play only logo role.
            if (!props.settings.theme.pinnedSidebar) {
              props.settings.theme.togglePinnedSidebar();
            }
          }}
        />
        <div className="pin" onClick={props.settings.theme.togglePinnedSidebar} />
      </div>
    );
  })
);

const SidebarItem = observer((props) => {
  const baseUrl = props.instance.navItemOverride || props.instanceBasedPath;
  return (
    <li className={baseUrl && baseUrl.startsWith(`/${props.item.url}`) ? 'active' : ''}>
      <InstanceLink
        to={`/${props.item.url}`}
        className={props.item.iconClass || props.item.url}
        disabled={props.disabled}
      >
        <span>{props.item.title}</span>
      </InstanceLink>
    </li>
  );
});

@withRouter
@inject('store')
@observer
class LeftSidebar extends React.Component {
  firstGroup = [
    { url: 'dashboard', title: 'Dashboard', requiredReadiness: InstanceReadinessStages.hostExists },
    { url: 'hosts', title: 'Hosts', requiredReadiness: InstanceReadinessStages.hostAppExists },
    { url: 'containers', title: 'Containers', requiredReadiness: InstanceReadinessStages.hostAppExists },
    { url: 'scripts', title: 'Scripts', requiredReadiness: InstanceReadinessStages.hostExists },
    { url: 'ansible', title: 'Ansible', requiredReadiness: InstanceReadinessStages.hostExists },
  ];

  secondGroup = [
    { url: 'users', title: 'Users' },
    { url: 'agents', title: 'Agents' },
    { url: 'api', title: 'API', iconClass: 'devs' },
  ];

  getInstanceName = () => {
    let instance;
    if (!this.props.location.pathname.startsWith('/i/')) {
      if (this.props.store.Instances.lastSelected) {
        instance = this.props.store.Instances.lastSelected;
      } else if (this.props.store.Instances.items.length > 0) {
        instance = this.props.store.Instances.items[0].name;
      }
    } else {
      instance = this.props.store.Instances.current;
    }

    return instance;
  };

  render() {
    if (!this.props.store.Instances.loaded) {
      return null;
    }

    const instance = this.props.store.Instances.getByName(this.getInstanceName());
    if (
      !instance ||
      // FIXME(andreykurilin): manage this per nab item itself
      instance.readinessState !== InstanceReadinessStages.hostExists
    ) {
      return null;
    }

    let instanceBasedPath;
    if (this.props.location.pathname.startsWith('/i/')) {
      instanceBasedPath = this.props.location.pathname.replace(`/i/${instance.name}`, '');
      instanceBasedPath = instanceBasedPath.replace('//', '/');
    } else {
      instanceBasedPath = false;
    }

    return (
      <div className="sidebar">
        <BrandBox />

        {/* Start Left Navigation */}
        <nav>
          <ul>
            {this.firstGroup.map((item) => (
              <SidebarItem
                key={item.url}
                item={item}
                instance={instance}
                instanceBasedPath={instanceBasedPath}
                disabled={
                  item.hasOwnProperty('requiredReadiness') ? instance.readinessState < item.requiredReadiness : false
                }
              />
            ))}
            <li className="divider" />
            {this.secondGroup.map((item) => (
              <SidebarItem key={item.url} item={item} instance={instance} instanceBasedPath={instanceBasedPath} />
            ))}
          </ul>
        </nav>
        {/* End Left Navigation */}
      </div>
    );
  }
}

const CentralNavElement = inject('store')(
  observer((props) => {
    if (!props.store.Instances.loaded) {
      return null;
    }
    const instance = props.store.Instances.getCurrent();
    if (!instance) {
      return null;
    }
    return (
      <Provider key={`nav-${instance.name}`} instance={instance}>
        <InstanceGlobalSearch />
      </Provider>
    );
  })
);

const OrganizationLabel = (props) => {
  if (process.env.DEMO_MODE) {
    return <div className="text">{props.name}</div>;
  }
  return (
    <Link to="/organization/info" className={props.pathname.startsWith('/organization/') ? 'active' : ''}>
      {props.name}
    </Link>
  );
};

@withRouter
@inject('profile', 'org')
@observer
export default class NavBar extends React.Component {
  render() {
    if (!this.props.profile.isLogged) {
      return '';
    }
    const pathname = this.props.location.pathname;

    return (
      <>
        <div className="header">
          {/* Top bar */}
          <div className="headerbar">
            <div className="headerbar-container">
              <Link to="/" className={`icon home ${pathname === '/' ? 'active' : ''}`} />
              <CentralNavElement />
              <a className="spacer" />
              <OrganizationLabel name={this.props.org.Info.displayName} pathname={pathname} />
              <Terminals />
              <Link
                to="/catalog/models"
                className={`icon models ${pathname.startsWith('/catalog/models') ? 'active' : ''}`}
                title="Models"
              />
              <span className="divider" />
              <Link className={`icon user ${pathname === '/profile' ? 'active' : ''}`} to="/profile" />
              <Icon className="logout" onClick={() => this.props.profile.logout(LoginActions.logout)} />
            </div>
          </div>
          {/* end of top bar */}
        </div>
        <LeftSidebar />
      </>
    );
  }
}

@withRouter
@inject('instance')
@observer
export class InstanceNavItemOverrider extends React.Component {
  componentDidMount() {
    this.props.instance.setNavItemOverride(
      this.props.baseUrl.startsWith('/') ? this.props.baseUrl : `/${this.props.baseUrl}`
    );
  }

  componentWillUnmount() {
    this.props.instance.setNavItemOverride(null);
  }

  render() {
    return null;
  }
}

export const InstanceLink = observer((props) => {
  const { store } = useStores();
  let to;
  if (props.disabled) {
    // FIXME(andreykurilin); handle disabled better
    return null;
  }
  if (store.Instances.current === null) {
    to = '/';
  } else {
    to = `/i/${store.Instances.current}${props.to}`;
  }
  return <Link {...props} to={to} />;
});
