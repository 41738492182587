import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';

import { PageHeader, ContainerBox } from 'components/Page';
import { SidedPanel, LeftPanel } from 'components/layout/Panels';
import Checkbox from 'components/Checkbox';

import TextEditor from 'components/input/TextEditor';

export default inject('instance')(
  observer((props) => {
    const [advanceFilters, setAdvanceFilters] = useState(false);

    const [virtEnv, setVirtEnv] = useState(false);
    const [installSteps, setInstallSteps] = useState([]);

    const setNewVirtEnv = () => {
      if (virtEnv) {
        setInstallSteps([
          '# Install ansible galaxy collection',
          'ansible-galaxy collection install itlook.it',
          '',
          '# Install and source virtualenv',
          'virtualenv itlook',
          '. ./itlook/bin/activate',
          '',
          '# Install ansible and other python requirements',
          'pip install ansible requests websocket-client "PyJWT>=2.0.0"',
        ]);
      } else {
        setInstallSteps([
          '# Install ansible galaxy collection',
          'ansible-galaxy collection install itlook.it',
          '# Install python requirements',
          'pip install requests websocket-client "PyJWT>=2.0.0"',
        ]);
      }
    };

    useEffect(() => setNewVirtEnv(false), [virtEnv]);

    return (
      <ContainerBox>
        <PageHeader title="Ansible Integration Setup" documentTitle="Ansible" />
        <SidedPanel className="ansible">
          <LeftPanel>
            <form>
              <h4>1. Install Requirements</h4>
              <h5> ITLook uses custom inventory and connection plugins that have to be installed</h5>
              <div className="form-box">
                <fieldset className="fieldset">
                  <Checkbox label="Use Virtualenv" checked={virtEnv} onChange={() => setVirtEnv(!virtEnv)} />
                </fieldset>

                <TextEditor
                  label={`Install requirements ${virtEnv ? 'inside virtual env' : 'system wide'}`}
                  key={`${virtEnv}-${installSteps.length}`}
                  syntax="bash"
                  value={installSteps.join('\n')}
                  readOnly
                />
              </div>

              <h4>2. Generate ITLook Persistent Key</h4>
              <h5>Execute commands below to obtain non expiring token</h5>
              <div className="form-box">
                <TextEditor
                  label="execute."
                  key="env-vars"
                  syntax="bash"
                  value={[
                    '# Your short living token for API access.',
                    `export ITLOOK_TOKEN="${props.instance.token}"`,
                    '',
                    '# Generate non expiring key for API access',
                    "export ITLOOK_KEY=$(curl -X 'POST' \\",
                    `'${props.instance.origin}/i/api/v1/auth/access-key-sets?format=base64' \\`,
                    "-H 'accept: application/json' \\",
                    "-H 'Content-Type: application/json' \\",
                    '-H "Authorization: Bearer ${ITLOOK_TOKEN}" \\',
                    '-d \'{"description": "ansible key"}\')',
                  ].join('\n')}
                />
              </div>

              <h4>3. Update ansible configuration to use ITLook </h4>
              <h5> Add lines to ansible.cfg</h5>
              <div className="form-box">
                <TextEditor
                  label="Save Ansible config to ansible.cfg"
                  key="ansible.cfg"
                  syntax="ini"
                  value={[
                    '[defaults]',
                    'callback_whitelist = itlook.it.callback',
                    'callbacks_enabled = itlook.it.callback',
                    '',
                    '[ssh_connection]',
                    'pipelining = True',
                    '',
                    '[connection]',
                    'pipelining = True',
                  ].join('\n')}
                  readOnly
                />
              </div>

              <h4>4. Create ITLook dymamic inventory</h4>
              <h5>Create dynamic inventory itlook.yaml</h5>
              <div className="form-box">
                <fieldset className="fieldset">
                  <Checkbox
                    label="Show advance configuration"
                    checked={advanceFilters}
                    onChange={() => setAdvanceFilters(!advanceFilters)}
                  />
                </fieldset>
                <TextEditor
                  label="Save dynamic inventory to itlook.yaml"
                  key={`itlook.yaml-${advanceFilters}`}
                  syntax="yaml"
                  value={[
                    '---',
                    'plugin: "itlook.it.inventory"',
                    'itlook_url: "https://my.itlook.io"',
                    '',
                    '# Put itlook key here, otherwise export it via env variable.',
                    '# itlook_key: your_key',
                    ...((advanceFilters && [
                      '',
                      '# Type of targets: `containers`, `hosts` or `both`.',
                      'type: both',
                      '',
                      '# If specified, only those agents are going to be used.',
                      '# agents:',
                      '#   - AGENT_ID1',
                      '#   - AGENT_ID2',
                      '',
                      '# Targets with these labels will be included to inventory.',
                      '# include_labels:',
                      '#   - label-1',
                      '#   - label-2',
                      '',
                      '# Targets with these labels will be excluded from inventory.',
                      '# exclude_labels:',
                      '#   - label-3',
                      '#   - label-4',
                    ]) ||
                      []),
                  ].join('\n')}
                  readOnly
                />
              </div>
              <h4>5. Use Ansible playbooks as is</h4>
              <h5>Create playbook test.yaml</h5>
              <div className="form-box">
                <TextEditor
                  label="test.yaml"
                  key="test.yaml"
                  syntax="yaml"
                  value={[
                    '---',
                    '- name: test',
                    '  hosts: itlook_managed',
                    '  become: false',
                    '  gather_facts: false',
                    '  tasks:',
                    '    - name: LS',
                    '      command: ls -lah',
                  ].join('\n')}
                  readOnly
                />
              </div>
              <h5>Run usual ansible commands with itlook inventory</h5>
              <div className="form-box">
                <TextEditor
                  label="execute"
                  key="run run run"
                  syntax="bash"
                  value={['ansible-inventory -i itlook.yaml --graph', 'ansible-playbook test.yaml -i itlook.yaml'].join(
                    '\n'
                  )}
                  readOnly
                />
              </div>
            </form>
          </LeftPanel>
        </SidedPanel>
      </ContainerBox>
    );
  })
);
