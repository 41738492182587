import 'regenerator-runtime/runtime';

import React, { useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import ClickAwayListener from 'react-click-away-listener';

import Input from 'components/forms/Input';
import LegacyInput from 'components/input/Input';
import { KeyCodes } from 'Constants';
import variables from 'styles/_variables.module.scss';
import { formatHostInfo } from 'lib/hosts';
import { UUID4 } from 'lib/uuid-utils';

import 'xterm/css/xterm.css';
import Icon from 'components/Icon';

const debounce = (func, wait) => {
  let prevTimeout = null;

  const debouncedFunc = (...args) => {
    if (prevTimeout) {
      clearTimeout(prevTimeout);
    }
    prevTimeout = setTimeout(() => {
      func(...args);
    }, wait);
  };

  return debouncedFunc;
};

const stringToArrayBuffer = (byteString) => {
  const byteArray = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.codePointAt(i);
  }
  return byteArray;
};

const FileInfo = (props) => {
  // Link to source: https://gist.github.com/lanqy/5193417
  const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  };

  const fileInfo = props.fileInfo;
  let message = '';
  if (fileInfo) {
    if (!fileInfo.exists) {
      message = <span>file not found</span>;
    } else {
      message = (
        <>
          <span>{fileInfo.mime_type}</span>
          <span>{bytesToSize(fileInfo.bytes)}</span>
        </>
      );
    }
  }
  return <span className="help-text">{message}</span>;
};

const FileDownloader = observer((props) => {
  const socket = props.socket;
  const termAdd = props.termAdd;
  const fileRef = useRef(null);
  const [path, setPath] = useState(null);
  const [fileContent, setFileContent] = useState('');
  const [fileInfo, setFileInfo] = useState(null);
  const [canDownload, setCanDownload] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(undefined);

  const app = props.record.root_1.app;

  async function download() {
    socket.afterOpen(async () => {
      setDownloading(true);
      setCanDownload(false);
      const appConn = socket.ensureAppConnection(app);
      const socketCommand = 'file:download';
      const initArs = {
        id: props.record.root_1.id,
        source: path,
      };
      const fileSocket = await appConn.createSocket(socketCommand);
      let fileData = '';
      fileSocket._onMessage = (message) => {
        if (message) {
          fileData += atob(message);
        } else {
          const blob = new Blob([stringToArrayBuffer(fileData)], { type: fileInfo.mime_type });
          const url = window.URL.createObjectURL(blob);
          setFileContent(url);
          fileRef.current.click();
          window.URL.revokeObjectURL(url);
          setDownloading(false);
          setCanDownload(true);
        }
      };
      await fileSocket.send_socket_rpc('init', initArs);
    });
    // TODO: add appConn.dispose() call
  }

  const onFilenameChange = debounce((name) => {
    setPath(name);
    const fetchData = async () => {
      let filePath = name;
      if (!filePath.startsWith('/')) {
        const dir = await termAdd.socket.send_socket_rpc('cwd');
        if (filePath.startsWith('./')) {
          filePath = filePath.slice(2);
        }
        filePath = `${dir.cwd}/${name}`;
      }
      const info = await termAdd.socket.send_socket_rpc('file', {
        path: filePath,
      });
      setPath(filePath);
      setCanDownload(info.exists);
      if (!info.exists) {
        setErrorMsg('File not found');
        setFileInfo(info);
      } else {
        setErrorMsg(undefined);
        setFileInfo(info);
      }
    };
    fetchData().catch((err) => {
      setFileInfo(null);
      setErrorMsg(err);
    });
  }, 225);

  const filename = path ? path.slice(path.lastIndexOf('/') + 1) : '';

  return (
    <ClickAwayListener onClickAway={props.onClose}>
      <div className="file-download-modal">
        <div className="header">
          <div className="title">File Download</div>
          <div className="close-btn" onClick={props.onClose} />
        </div>
        <div className="body">
          <form>
            <div className="form-box">
              <div className="form-item">
                <LegacyInput
                  key="display-name"
                  inputType="text"
                  autoFocus
                  thin
                  placeholder="File name"
                  className=""
                  onChange={onFilenameChange}
                  error={errorMsg}
                  downloading={downloading}
                  disabled={downloading}
                >
                  <button type="button" className="btn btn-default" onClick={download} disabled={!canDownload}>
                    Download
                  </button>
                </LegacyInput>
                <a ref={fileRef} download={filename} href={fileContent} />
                <FileInfo fileInfo={fileInfo} />
              </div>
            </div>
          </form>
        </div>
        <div className="footer" />
      </div>
    </ClickAwayListener>
  );
});

const FileUploader = (props) => (
  <div className="file-upload-modal" style={{ display: 'none' }}>
    <div className="header">
      <div className="title">File Upload</div>
      <div className="close-btn" onClick={props.onClose} />
    </div>
    <div className="body">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-box">
          <div className="item-container">
            <div className="file-upload-select">
              <div className="file-select-button">Choose File or Drag and Drop Here</div>
              <div className="file-select-name" style={{ display: 'none' }}>
                No file chosen...
              </div>
              <Input key="file-to-upload" store={props.termSession.fileUploader} />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div className="footer" />
  </div>
);

export const TerminalSearch = (props) => {
  const [pattern, setPattern] = useState('');

  const searchOption = {
    decorations: {
      matchBorder: variables.chartOrange3,
      activeMatchBackground: variables.chartOrange2,
    },
  };

  const findNext = () => {
    props.search.findNext(pattern, searchOption);
  };

  const findPrev = () => {
    props.search.findPrev(pattern, searchOption);
  };

  useEffect(() => {
    if (pattern) {
      findNext();
    }
  }, [pattern]);

  const onChange = (e) => {
    setPattern(e.target.value);
  };
  const onKeyDown = (e) => {
    if (e.keyCode === KeyCodes.ARROW_UP) {
      if (pattern) {
        findPrev();
      }
      e.preventDefault();
    } else if (e.keyCode === KeyCodes.ENTER || e.keyCode === KeyCodes.ARROW_DOWN) {
      if (pattern) {
        findNext();
      }
      e.preventDefault();
    }
  };
  return (
    <div className="terminal-search-container">
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="form-box">
          <div className="form-item terminal-search">
            <div className="item-container">
              <input type="text" placeholder="Search" onChange={onChange} onKeyDown={onKeyDown} />
              <button type="button" className="btn-only-icon prev" onClick={findPrev} />
              <button type="button" className="btn-only-icon next" onClick={findNext} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const TerminalActions = observer((props) => {
  return (
    <div className="terminal-tools-container">
      <TerminalSearch search={props.search} />
      <button
        type="button"
        className="btn btn-default btn-transparent btn-small download"
        onClick={() => props.setAction('download')}
      >
        <Icon className="terminal-download" />
        Download
      </button>
      <button
        type="button"
        className="btn btn-default btn-transparent btn-small upload"
        onClick={props.termSession.fileUploader.onClick}
      >
        <Icon className="terminal-upload" />
        {props.termSession.fileUploader.buttonLabel}
      </button>
    </div>
  );
});

const TerminalConnectionError = observer((props) => (
  <div className="terminal-welcome-screen">
    <h4>Can't connect to terminal. {props.record.inventory_1.statusDescription}</h4>
  </div>
));

const getAccessUser = (hostData) => {
  return hostData ? hostData.get('accessUser') : '';
};

export const NewTerminalSession = observer((props) => {
  if (props.hidden) {
    return null;
  }

  const hostData = props.record.data.get('std::host/Host:1');
  const containerData = props.record.data.get('std::host/Container:1');

  const modelId = props.record.root_1.id;

  const { hostname } = formatHostInfo(hostData, containerData);
  const accessUser = getAccessUser(hostData);
  const app = props.record.root_1.app;

  const onClick = () => {
    const sessionId = UUID4();
    if (props.sessionManager.socket == null) {
      props.sessionManager.setSocket(props.instance.getSocket());
    }
    props.sessionManager.socket.afterOpen(() => {
      props.sessionManager.ensureAppConnection(
        props.instance.name,
        app,
        sessionId,
        modelId,
        hostname,
        accessUser,
        props.record,
        props.instance
      );
      props.sessionManager.showTerminals();
    });
  };

  return (
    <button type="button" className="btn btn-success" onClick={onClick}>
      <Icon className="terminal-btn" />
      New session
    </button>
  );
});

NewTerminalSession.suites = (record) => record.data.has('std::host/Host:1') || record.data.has('std::host/Container:1');

const TerminalHeader = observer((props) => {
  const searchAdd = props.searchAddon;
  const socket = props.socket;
  const termAdd = props.termAddon;
  const hostData = props.hostData;
  const containerData = props.containerData;

  const [currentAction, setCurrentAction] = useState(null);

  const { hostname, userAtIP } = formatHostInfo(hostData, containerData);

  let modal = null;
  if (currentAction === 'download') {
    modal = (
      <FileDownloader
        record={props.record}
        instance={props.instance}
        socket={socket}
        termAdd={termAdd}
        onClose={() => setCurrentAction(null)}
      />
    );
  }

  return (
    <>
      <div className="terminal-tools-container">
        {!containerData && (
          <div className="terminal-session-info">
            Connected to: {hostname} <span>{userAtIP}</span>
          </div>
        )}
        {containerData && (
          <div className="terminal-session-info">
            Connected to: container {hostname} <span>{userAtIP}</span>
          </div>
        )}
        <div className="spacer" />
        <TerminalActions
          termSession={props.termSession}
          currentAction={currentAction}
          setAction={setCurrentAction}
          search={searchAdd}
        />
      </div>
      <div className="global-modals-placeholder">
        {modal}
        <FileUploader
          record={props.record}
          instance={props.instance}
          socket={socket}
          termAdd={termAdd}
          termSession={props.termSession}
          onClose={() => setCurrentAction(null)}
        />
      </div>
    </>
  );
});

const XTerm = observer((props) => {
  const sessionManager = props.sessionManager;

  const [hasSession, setHasSession] = useState(null);

  const [termAdd, setTermAdd] = useState(null);
  const [searchAdd, setSearchAdd] = useState(null);

  if (sessionManager.socket == null) {
    sessionManager.setSocket(props.instance.getSocket());
  }

  const hostData = props.record.data.get('std::host/Host:1');
  const containerData = props.record.data.get('std::host/Container:1');

  const ref = useRef(sessionId);

  const session = sessionManager.getActiveSession();
  const sessionId = session.id;
  useEffect(() => {
    if (session) {
      setHasSession(true);
    }
    if (ref.current && hasSession) {
      session.init(() => {
        setTermAdd(session.getTerminalAddon());
        setSearchAdd(session.getSearchAddon());
      });

      session.term.focus();
      return () => {
        // NOTE: do not call dispose to save session
        // termSession.term.dispose();
      };
    }
  }, [sessionManager.socket, hasSession, sessionId]);

  const uploadHelper =
    session && session.fileUploader.dragActive ? (
      <div className="file-upload-zone" key="file-upload-zone">
        <div className="icon upload-file" />
        <p>To upload your file, please drag and drop it into this area.</p>
      </div>
    ) : null;
  return (
    <div
      className="terminal-body-wrapper"
      onDrop={session && session.fileUploader.handleDrop}
      onDragOver={session && session.fileUploader.onDragOver}
      onDragEnterCapture={session && session.fileUploader.onDragOver}
      onDragLeave={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragLeaveCapture={session && session.fileUploader.onDragLeaveCapture}
    >
      {hasSession && session && (
        <TerminalHeader
          record={props.record}
          searchAddon={searchAdd}
          termSession={session}
          socket={sessionManager.socket}
          termAddon={termAdd}
          hostData={hostData}
          containerData={containerData}
        />
      )}
      <div className="terminal-window">
        <div ref={ref} id={sessionId} style={{ height: '100%' }} />
        {uploadHelper}
        {!hasSession && props.record.inventory_1.status !== 'ok' && <TerminalConnectionError record={props.record} />}
      </div>
    </div>
  );
});

export default XTerm;
