import React, { useEffect } from 'react';
import { observer, Provider } from 'mobx-react';
import { BrowserRouter, Route, Switch, useParams, Redirect } from 'react-router-dom';

import store from 'stores/Store';

// common stuff
import { PopoversRootContainer } from 'components/Popover';
import NotFound from 'pages/NotFound';
import { Spinner } from 'components/Loader';
import PageBody from 'components/Page';
import { InstanceStates, InstanceReadinessStages, HOST_APP_TYPE } from 'Constants';

// Auth
import Login from 'pages/Auth/Login';
import OAuth2 from 'pages/Auth/OAuth';
import { PasswordRecoveryRequest, PasswordRecoverySetPassword } from 'pages/Auth/PasswordRecovery';
import SignUp from 'pages/Auth/SignUp';
import RedirectToLogin from 'components/RedirectToLogin';

// Dev stuff
import ExpPage from 'experiments/ExpFullPage';

// Global, instance unrelated
import Welcome from 'pages/Welcome';
import ModelPage from 'pages/Models/Model';
import ModelsList from 'pages/Models/ModelsList';
import OrganizationViews from 'pages/Organization/Organization';
import UserCreate from 'pages/Organization/Users/UserCreate';
import UserInvite from 'pages/Organization/Users/UserInvite';
import RightSideBarWithUserInfo from 'pages/Organization/Users/RightSideBarWithUserInfo';
import UserProfile from 'pages/Profile';

// instance related
import InstanceDashboard from 'pages/Instances/Dashboard';
import InstanceNotReady from 'pages/Instances/InstanceNotReady';
import RecordView from 'pages/Instances/RecordView/RecordView';
import HostsSearchPage from 'pages/Instances/Hosts/HostsList';
import NewHostPage from 'pages/Instances/Hosts/RegisterNewHost';
import ContainersSearchPage from 'pages/Instances/Containers/ContainersList';

import ScriptsList from 'pages/Instances/Scripts/ScriptsList';
import ScriptNew from 'pages/Instances/Scripts/ScriptNew';

import Ansible from 'pages/Instances/Ansible';

import { GlobalSearchTypeSwitcher } from 'pages/Instances/GlobalSearch/NavigationComponent';
import { SearchResultsPage } from 'pages/Instances/GlobalSearch/SearchResults';

import UsersSearchPage from 'pages/Instances/Users/UsersList';

import AgentNew from 'pages/Instances/Agents/AgentNew';
import AgentsList from 'pages/Instances/Agents/AgentsList';

import InstanceSettings from 'pages/Instances/Instance';
import Api from 'pages/Instances/Api/Api';

const isDevEnv = process.env.NODE_ENV === 'development' || !process.env.NODE_ENV;

const AnonymousView = () => (
  <React.StrictMode>
    <Switch>
      <Route path="/" component={Login} exact />
      {isDevEnv && <Route path="/exp" component={ExpPage} exact />}
      {isDevEnv && <Route path="/exp/:page" component={ExpPage} exact />}
      <Route path="/reset-password" component={PasswordRecoveryRequest} exact />
      <Route path="/reset-password/:token" component={PasswordRecoverySetPassword} exact />
      <Route path="/signup" component={SignUp} exact />
      <Route path="/oauth2/callback/:provider" component={OAuth2} />
      <Route component={RedirectToLogin} />
    </Switch>
  </React.StrictMode>
);

const InstanceLoader = observer((props) => {
  const { instanceName } = useParams();

  useEffect(() => {
    store.Instances.setCurrentSelectedItem(instanceName);
    const instance = store.Instances.getByName(instanceName);
    if (instance && instance.token === null) {
      instance.getToken();
    }
  }, [instanceName]);

  const instance = store.Instances.getByName(instanceName);
  if (instance === undefined) {
    return <NotFound message={`Oops. We cannot find an instance with the name '${instanceName}'.`} />;
  }

  if (instance.token === null || instance.status === undefined || instance.status === null) {
    return <Spinner />;
  }

  if (instance.status !== InstanceStates.Ready) {
    return <InstanceNotReady key={instance.id} instance={instance} />;
  }

  if (instance.readinessState === InstanceReadinessStages.unknown) {
    return <Spinner />;
  }

  let component;
  if (instance.readinessState === InstanceReadinessStages.notHostApp) {
    component = <AgentNew />;
  } else if (instance.readinessState === InstanceReadinessStages.hostAppExists) {
    component = <NewHostPage applicationID={instance.Applications.filter(HOST_APP_TYPE)[0].id} />;
  } else {
    component = React.createElement(props.component, { instance: instance });
  }

  return (
    <Provider key={instanceName} instance={instance}>
      {component}
    </Provider>
  );
});

const loadedInstance = (component) => {
  return () => <InstanceLoader component={component} />;
};

const LoggedView = observer(() => {
  useEffect(() => {
    if (!store.Instances.loaded && !store.Instances.loading) {
      store.Instances.fetch();
    }
  }, [store.Instances.loaded]);

  return (
    <PageBody>
      <React.StrictMode>
        <PopoversRootContainer />
        <div className="content">
          {!store.Instances.loaded ? (
            <Spinner />
          ) : (
            <Switch>
              <Route path="/" exact>
                <Redirect to="/i/main/" />
              </Route>
              <Route path="/i/:instanceName/" exact>
                <Redirect to="/i/main/dashboard" />
              </Route>
              <Route path="/i/:instanceName/dashboard" component={loadedInstance(InstanceDashboard)} exact />
              <Route path="/i/:instanceName/search" component={loadedInstance(SearchResultsPage)} exact />
              <Route path="/i/:instanceName/records/:recordId/" component={loadedInstance(RecordView)} />
              <Route path="/i/:instanceName/hosts" component={loadedInstance(HostsSearchPage)} exact />
              <Route path="/i/:instanceName/hosts/register" component={loadedInstance(NewHostPage)} exact />
              <Route path="/i/:instanceName/containers" component={loadedInstance(ContainersSearchPage)} exact />
              <Route path="/i/:instanceName/scripts" component={loadedInstance(ScriptsList)} exact />
              <Route path="/i/:instanceName/scripts/create" component={loadedInstance(ScriptNew)} exact />
              <Route path="/i/:instanceName/ansible" component={loadedInstance(Ansible)} exact />
              <Route path="/i/:instanceName/instance" component={loadedInstance(InstanceSettings)} exact />
              <Route path="/i/:instanceName/users" component={loadedInstance(UsersSearchPage)} exact />
              <Route path="/i/:instanceName/api" component={loadedInstance(Api)} />
              <Route path="/i/:instanceName/agents" component={loadedInstance(AgentsList)} exact />
              <Route path="/i/:instanceName/agents/launch" component={loadedInstance(AgentNew)} exact />
              <Route path="/i/:instanceName/:item" component={loadedInstance(GlobalSearchTypeSwitcher)} />
              <Route path="/catalog/models" component={ModelsList} exact />
              <Route path="/catalog/models/:modelOrg:::modelGroup/:modelIdentifier" component={ModelPage} />
              <Route path="/organization/users/create" component={UserCreate} exact />
              <Route path="/organization/users/invite" component={UserInvite} exact />
              <Route path="/organization" component={OrganizationViews} />
              <Route path="/profile" component={UserProfile} exact />
              <Route path="/signup" component={Welcome} exact />
              <Route component={NotFound} />
            </Switch>
          )}
        </div>
        <div className="rightbar">
          <Switch>
            <Route path="/organization/users" component={RightSideBarWithUserInfo} exact />
          </Switch>
        </div>
      </React.StrictMode>
    </PageBody>
  );
});

@observer
class App extends React.Component {
  render() {
    const view = (store.Profile.token && <LoggedView />) ||
      (store.Profile.isLogged && store.Profile.loading && <div className="loader" />) || <AnonymousView />;

    return (
      <Provider
        store={store}
        profile={store.Profile}
        settings={store.Settings}
        org={store.Organization}
        notifications={store.Notifications}
      >
        <BrowserRouter>{view}</BrowserRouter>
      </Provider>
    );
  }
}

export default App;
