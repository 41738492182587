import React from 'react';

import { inject } from 'mobx-react';

import { TabViewsSwitcher, TabView, TabViewContent } from 'components/TabsPageView';
import { PageHeader } from 'components/Page';

import QuickAccess from 'pages/Instances/Api/QuickAccess';
import Swagger from 'pages/Instances/Api/Swagger';

export default inject(
  'instance',
  'org'
)((props) => (
  <TabViewsSwitcher>
    <TabView title="Management API" key="m-api" url="m-api">
      <PageHeader title="Management API" />
      <TabViewContent>
        <Swagger swaggerStore={props.org.Info.Swagger} />
      </TabViewContent>
    </TabView>

    <TabView title="Instance API" key="i-api" url="i-api">
      <PageHeader title="Instance API" />
      <TabViewContent>
        <Swagger swaggerStore={props.instance.Swagger} />
      </TabViewContent>
    </TabView>

    <TabView title="Quick access" key="access" url="quick-access">
      <PageHeader title="Quick access" />
      <TabViewContent>
        <QuickAccess />
      </TabViewContent>
    </TabView>
  </TabViewsSwitcher>
));
