import { types, getParentOfType, applyPatch } from 'mobx-state-tree';
import Store from 'stores/Store';

const CachedImage = types
  .model({
    src: types.identifier,
    size: 400,
    ready: false,
  })
  .actions((self) => ({
    afterCreate: () => {
      self.image = new window.Image(self.size, self.size);
      self.image.onload = self.setLoaded;
      self.image.onerror = self.setFailedToLoad;
      self.image.src = self.src;
    },
    setLoaded: () => {
      self.ready = true;
      getParentOfType(self, CachedImages).refreshStatus();
    },
    setFailedToLoad: () => {
      // figure out what to do in case of failures;
      self.setLoaded();
    },
  }))
  .volatile(() => ({
    image: null,
  }));

export const CachedImages = types
  .model('CachedImages', {
    images: types.map(CachedImage),
    ready: false,
    lastUpdated: types.optional(types.integer, 0),
  })
  .actions((self) => ({
    refreshStatus: () => {
      self.ready = !Array.from(self.images.values())
        .map((img) => img.ready)
        .filter((el) => !el).length;
      if (self.ready) {
        getParentOfType(self, RecordGraph).updateLoadedAt();
      }
    },
    updateCache: (images) => {
      let newImages = 0;
      images.forEach((image) => {
        const cachedImg = self.images.get(image);
        if (cachedImg === undefined) {
          self.ready = false;
          self.images.put({ src: image });
          newImages += 1;
        }
      });
      if (newImages === 0) {
        self.refreshStatus();
      }
    },
  }));

/* Make store for tooltip that will support auto-generation */
export const RecordGraphTooltip = types
  .model('RecordGraphTooltip', {
    onlySignificantLinks: types.optional(types.boolean, true),
    autoRefresh: types.optional(types.boolean, true),
    hideDeleted: types.optional(types.union(types.integer, types.boolean), 21600),
    algorithm: types.optional(
      types.union(types.literal('just_do_it'), types.literal('1hop'), types.literal('2hops'), types.literal('3hops')),
      'just_do_it'
    ),
    controlType: types.optional(
      types.union(types.literal('orbit'), types.literal('fly'), types.literal('trackball')),
      'orbit'
    ),
  })
  .actions((self) => ({
    updateData: (values) => {
      const validKeys = Object.keys(values).filter((key) => self.hasOwnProperty(key));
      const patches = validKeys.map((key) => ({
        op: 'replace',
        path: `/${key}`,
        value: values[key],
      }));
      applyPatch(self, patches);
    },
  }));

export const RecordGraphNode = types
  .model('RecordGraphNode', {
    id: types.string,
    version: types.integer,
    model: types.string,
    displayName: types.string,
    svgSrc: types.string,
    status: types.string,
    color: types.maybeNull(types.string),
  })
  .volatile(() => ({}));

export const RecordGraphLink = types
  .model('RecordGraphLink', {
    source: types.string,
    target: types.string,
    reference: types.string,
    color: types.maybeNull(types.string),
  })
  .volatile(() => ({}));

export const RecordGraphData = types
  .model('RecordGraphData', {
    images: types.optional(CachedImages, {}),
    nodes: types.array(RecordGraphNode),
    links: types.array(RecordGraphLink),
  })
  .views(() => ({
    getSvgSrc(model) {
      return Store.Models.getPicture(model);
    },
  }))
  .actions((self) => ({
    setData({ nodes, references }) {
      self.images.updateCache(Object.values(nodes).map((el) => self.getSvgSrc(el['@model'])));

      self.nodes.replace(
        Object.values(nodes).map((el) => ({
          id: el['std::types/Inventory:1'].id,
          version: el['std::types/Inventory:1'].version,
          model: el['@model'],
          displayName: el['std::types/Inventory:1'].displayName,
          svgSrc: self.getSvgSrc(el['@model']),
          status: el['std::types/Inventory:1'].status,
        }))
      );

      self.links.replace(
        references.map((el) => ({
          source: el.src,
          target: el.dst,
          reference: el.ref,
        }))
      );
    },
  }));

export const RecordGraph = types
  .model('RecordGraph', {
    data: types.optional(RecordGraphData, { nodes: [], links: [] }),
    loading: types.optional(types.boolean, true),
    loadedAt: types.optional(types.integer, 0),
    errorWhileLoading: types.maybeNull(types.string),
    tooltip: types.optional(RecordGraphTooltip, {}),
    intervalId: types.maybeNull(types.integer),
    recordId: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setRecordId(recordId) {
      self.recordId = recordId;
    },

    startAutoRefresh() {
      self.stopAutoRefresh();
      self.intervalId = setInterval(self.fetchGraphData, 5000);
    },
    stopAutoRefresh() {
      if (self.intervalId) {
        clearInterval(self.intervalId);
        self.intervalId = null;
      }
    },
    fetchGraphData() {
      const urlParams = {};

      if (self.tooltip.hideDeleted === false) {
        urlParams.skip_all_deleted = false;
      } else if (self.tooltip.hideDeleted === true) {
        urlParams.skip_all_deleted = true;
      } else {
        urlParams.skip_deleted_before = parseInt(Date.now() / 1000, 10) - self.tooltip.hideDeleted;
      }

      if (self.tooltip.onlySignificantLinks === false) {
        urlParams.significant_edges = false;
      }

      if (self.tooltip.algorithm) {
        urlParams.algorithm = self.tooltip.algorithm;
      }

      Store.TransportLayer.get({
        url: `/i/api/v1/record/${self.recordId}/discover`,
        query: urlParams,
        onSuccess: (response, response_data) => {
          self.loadGraphData(response_data.data);
        },
        onFailure: self.onFetchGraphError,
        onFinish: self.finishLoading,
      });
    },
    onFetchGraphError() {
      Store.Notifications.error(`Failed to fetch graph data for '${self.recordId}' record. Please try again later.`);
      self.updateLoadedAt();
    },
    updateLoadedAt() {
      self.loading = false;
      self.loadedAt = +new Date();
    },
    loadGraphData(data) {
      self.loading = true;
      self.data.setData(data);
    },
    finishLoading() {},
  }));
